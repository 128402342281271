import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  plugins: [
    createPersistedState({
      reducer: (state) => {
        return {
          username: state.username,
          email: state.email
        };
      }
    })
  ],
  state: {
    username: "",
    userInfo: [],
    notice: [],
    email: ""
  },
  mutations: {
    addname: (state, usernam) => {
      state.username = usernam;
    },
    addUserInfo: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    addNotice: (state, notice) => {
      state.notice = notice;
    },
    addEmail: (state, email) => {
      state.email = email;
    }
  },
  actions: {
    setName: (ctx, value) => {
      ctx.commit("name", value);
    }
  }
});
