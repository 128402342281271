<script  setup>
import { reactive, ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { http } from "@/utils/http.js";
import { useMessage } from "@/utils/useMessage.js";

const router = useRouter();
const codeBtn = ref(true);
const num = ref(60);
const userInfo = reactive({
  userName: "",
  password: "",
  userName1: "",
  password1: "",
  cdk: "",
  showFind: false,
  email: "",
  emailCode: "",
  isSendCode: false,
  findType: 1,
  findCodeBtn: false,
  showFind1: false,
});

const activeName = ref("first");

const handleClick = (tab, event) => {
  if (tab.props.name == "first") {
    userInfo.findType = 1;
  } else {
    userInfo.findType = 2;
  }
};

// 跳转主页
const onSubmit = () => {
  if (userInfo.findType == 1) {
    if (userInfo.cdk === "") {
      useMessage("error", "激活码不能为空");
      return;
    }

    http
      .post("/v1/user/findPwd", {
        cdk: userInfo.cdk,
      })
      .then((res) => {
        if (res.data.code != 200) {
          useMessage("error", res.data.message);
          return;
        }
        useMessage("success", res.data.message);
        userInfo.userName = res.data.data.username;
        userInfo.password = res.data.data.password;
        userInfo.showFind = true;
      })
      .catch((error) => {
        console.error("发生错误:", error);
        // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
      });
  } else {
    if (userInfo.email === "") {
      useMessage("error", "邮箱不能为空");
      return;
    }
    if (userInfo.emailCode == "") {
      useMessage("error", "邮箱验证码不能为空,请先发送邮箱验证码");
      return;
    }
    http
      .post("/v1/user/findByEmail", {
        email: userInfo.email,
        findCode: userInfo.emailCode,
      })
      .then((res) => {
        if (res.data.code != 200) {
          useMessage("error", res.data.message);
          return;
        }
        useMessage("success", res.data.message);
        userInfo.userName1 = res.data.data.username;
        userInfo.password1 = res.data.data.password;
        userInfo.findCodeBtn = false;
        userInfo.showFind1 = true;
      })
      .catch((error) => {
        console.error("发生错误:", error);
        // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
      });
  }
};
// 跳转登录
const toLogin = () => {
  router.push("/login");
};

const codeinterval = ref();

// 发送验证码
const sendCode = () => {
  userInfo.showFind1 = false;
  num.value = 60;
  userInfo.emailCode = "";
  if (userInfo.email == "") {
    useMessage("error", "请输入邮箱");
    return;
  }
  http
    .post("/v1/user/findSendCode", {
      email: userInfo.email,
    })
    .then((res) => {
      if (res.data.code != 200) {
        useMessage("error", res.data.message);
        return;
      }
      useMessage("success", res.data.message);
      codeBtn.value = false;
      userInfo.findCodeBtn = true;
      codeinterval.value = setInterval(() => {
        num.value--;
        if (num.value == 0) {
          codeBtn.value = true;
          clearInterval(codeinterval.value);
        }
      }, 1000);
    })
    .catch((error) => {
      console.error("发生错误:", error);
      // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
    });
};

onUnmounted(() => {
  clearInterval(codeinterval.value);
});
</script>
<template>
  <div class="h-screen font-sans login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="relative mx-10 sm:max-w-lg w-full">
        <div
          class="card bg-blue-400 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"
        ></div>
        <div
          class="card bg-red-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"
        ></div>
        <div
          class="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md"
        >
          <label
            for=""
            class="block mt-10 text-2xl text-gray-700 text-center font-semibold"
          >
            找回账号或密码
          </label>
          <div class="mt-10">
            <div class="relative flex pl-7 pr-7 mt-7">
              <el-tabs
                v-model="activeName"
                class="demo-tabs w-full h-56"
                :stretch="true"
                @tab-click="handleClick"
              >
                <el-tab-pane label="激活找回" name="first" class="mb-8">
                  <div class="flex relative flex pr-2 mt-7">
                    <div
                      class="flex justify-center items-center font-light ml-2"
                    >
                      激活码：
                    </div>
                    <input
                      v-model="userInfo.cdk"
                      placeholder="请输入激活码"
                      class="flex-1 mt-1 pl-4 block bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                    />
                  </div>
                  <div class="mt-8" v-show="userInfo.showFind">
                    <el-descriptions title="" :column="1" border>
                      <el-descriptions-item label="账号" align="center">{{
                        userInfo.userName
                      }}</el-descriptions-item>

                      <el-descriptions-item label="密码" align="center">{{
                        userInfo.password
                      }}</el-descriptions-item>
                    </el-descriptions>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="邮箱找回" name="second" class="mb-8">
                  <div>
                    <div class="flex relative mt-7 content-around flex-wrap">
                      <div class="flex justify-center items-center font-light">
                        邮箱：
                      </div>
                      <input
                        v-model="userInfo.email"
                        placeholder="请输入邮箱"
                        class="flex-1 mt-1 pl-4 block bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                      />
                      <button
                        v-show="!codeBtn"
                        class="whitespace-nowrap bg-gray-300 text-sm rounded-xl text-white focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x ml-2 p-3"
                        disabled
                      >
                        <div>
                          <span>{{ num }}s </span>后重新发送
                        </div>
                      </button>
                      <button
                        v-show="codeBtn"
                        class="whitespace-nowrap bg-red-500 text-sm rounded-xl mr-2 text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105 ml-2 p-3"
                        @click="sendCode"
                      >
                        发送邮箱验证码
                      </button>
                    </div>
                    <div
                      v-show="userInfo.findCodeBtn"
                      class="flex relative flex pl-7 pr-7 mt-7 justify-end"
                    >
                      <div
                        class="flex justify-center items-center font-light ml-2"
                      >
                        邮箱验证码：
                      </div>
                      <input
                        v-model="userInfo.emailCode"
                        placeholder="请输入邮箱验证码"
                        class="mt-1 pl-4 block bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                      />
                    </div>
                    <div class="mt-8" v-show="userInfo.showFind1">
                      <el-descriptions title="" :column="1" border>
                        <el-descriptions-item label="账号" align="center">{{
                          userInfo.userName1
                        }}</el-descriptions-item>

                        <el-descriptions-item label="密码" align="center">{{
                          userInfo.password1
                        }}</el-descriptions-item>
                      </el-descriptions>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>

            <div class="flex mt-12 pl-8 pr-8 mb-10">
              <button
                class="flex items-center justify-center bg-blue-500 font-normal w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="toLogin"
              >
                <el-icon><ArrowLeftBold /></el-icon>
                <div>返回登录</div>
              </button>
              <button
                class="bg-blue-500 ml-20 font-normal w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="onSubmit"
              >
                确认找回
              </button>
            </div>
          </div>
          <!-- <form class="mt-10" @click="onSubmit"></form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
}
</style>
