<script setup>
import menuService from "@/hooks/useMenu";
import { useScreenPixel } from "@/utils/web";
import { Monitor, User, ShoppingCart, MoonNight, InfoFilled } from '@element-plus/icons-vue'

// withDefaults 宏需要导入，以便设置 prop 的默认值
const props = defineProps({
  subMenu: Array,
  // activeKey: String,
  activeKey: {
    type: String,
    default: ''
  }
})

const getActiveKey = (menu, cmenu) => {
  return props.activeKey === ""
    ? menu.title + "-" + cmenu.title
    : props.activeKey + "-" + cmenu.title;
};

function handleMenuClick(cmenu) {
  const { sm, cmd } = useScreenPixel();
  if (sm.value || cmd.value) {
    menuService.toggleState();
  }
  menuService.linkPage(cmenu);
}

const iconMapping = {
  Monitor, User, ShoppingCart, MoonNight, InfoFilled
}
</script>

<template>
  <template v-for="(menu, index) in props.subMenu" :key="index">
    <template v-if="menu.hideParent">
      <el-menu-item
        v-for="(cmenu, key) in menu.children"
        :key="`sub_${key}`"
        :index="getActiveKey(menu, cmenu)"
        @click="handleMenuClick(cmenu)"
      >
        <!-- 你需要确保 menu.icon 是可用的组件 -->
        <!-- 如果 menu.icon 是字符串类型，你需要将其转换为相应的图标组件 -->
        <el-icon><component :is="iconMapping[menu.icon]" /></el-icon>
        <span>{{ cmenu.title }}</span>
      </el-menu-item>
    </template>

    <el-sub-menu v-else :index="menu.title">
      <template #title>
        <!-- 同上，确保 menu.icon 是可用的组件 -->
        <el-icon><component :is="iconMapping[menu.icon]" /></el-icon>
        <span>{{ menu.title }}</span>
      </template>
      <template v-for="(cmenu, key) in menu.children" :key="`child_${key}`">
        <!-- 确保 menu-item 是你定义的组件 -->
        <menu-item
          v-if="cmenu.children"
          :sub-menu="cmenu.children"
          :active-key="getActiveKey(menu, cmenu)"
        ></menu-item>
        <el-menu-item
          v-else
          :key="key"
          :index="getActiveKey(menu, cmenu)"
          @click="handleMenuClick(cmenu)"
          >{{ cmenu.title }}</el-menu-item
        >
      </template>
    </el-sub-menu>
  </template>
</template>

<style scoped>
/* 你的样式 */
</style>
