<template>
  <div class="auth-page">
    <router-view/>
  </div>
</template>

<style scoped lang="scss">
.auth-page {
height: 100%;
  background: url("@/assets/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>