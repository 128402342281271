<script >
// import v from "@/plugins/validate";
// import redirectService from "@/hooks/useRedirect";
import { useMessage } from "@/utils/useMessage.js";
// import { userStore } from "@/store/user";
import { useRouter } from "vue-router";
import { http } from "@/utils/http.js";
import { validatePhoneNumber } from "@/utils/login.js";
import { reactive, ref, onMounted } from "vue";
import { Hide, View } from "@element-plus/icons-vue";
export default {
  components: {
    Hide,
    View,
  },
  setup() {
    const router = useRouter();

    const userInfo = reactive({
      userName: "",
      password: "",
      isRemember: true,
    });

    const eyes = ref(true);
    const isShowPwd = ref();
    const HideEyes = () => {
      eyes.value = !eyes.value;
      if (eyes.value) {
        isShowPwd.value.type = "password";
      } else {
        isShowPwd.value.type = "";
      }
    };
    const onSubmit = () => {
      if (userInfo.userName == "" || userInfo.password == "") {
        useMessage("error", "账号密码不能为空");
        return;
      }
      // if (!validatePhoneNumber(userInfo.userName)) {
      //   useMessage("error", "账号必须为手机号！");
      //   return;
      // }

      http
        .post("/v1/user/login", {
          username: userInfo.userName,
          password: userInfo.password,
        })
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          localStorage.setItem("userToken", res.data.data);
          // 缓存的账号密码
          if (userInfo.isRemember) {
            if (userInfo.userName && userInfo.password) {
              localStorage.setItem("username", userInfo.userName);
              localStorage.setItem("password", userInfo.password);
            }
          }
          router.push("/");
        })
        .catch((error) => {
          console.error("发生错误:", error);
          // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
        });
    };
    const register = () => {
      router.push("/login/register");
    };
    const toFind = () => {
      router.push("/login/find");
    };

    onMounted(() => {
      if (
        localStorage.getItem("username") &&
        localStorage.getItem("password")
      ) {
        const username = localStorage.getItem("username");
        if (username !== null) {
          userInfo.userName = username;
        } else {
          // 处理 username 缺失的情况
          // 例如，赋值为一个默认值或执行错误处理逻辑
          userInfo.userName = "defaultUsername";
        }
        const password = localStorage.getItem("password");
        if (password !== null) {
          userInfo.password = password;
        } else {
          // 处理 password 不存在的情况，例如赋值为一个默认值或执行错误处理逻辑
          userInfo.password = "defaultPassword";
        }
      }
    });
    return {
      isShowPwd,
      userInfo,
      HideEyes,
      eyes,
      toFind,
      onSubmit,
      register,
    };
  },
};
</script>
<template>
  <div class="h-screen font-sans login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="relative mx-10 sm:max-w-sm w-full">
        <div
          class="card bg-blue-400 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"
        ></div>
        <div
          class="card bg-red-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"
        ></div>
        <div
          class="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md"
        >
          <label
            for=""
            class="block mt-3 text-2xl text-gray-700 text-center font-semibold"
          >
            登录
          </label>
          <div class="mt-10">
            <div class="relative">
              <input
                v-model="userInfo.userName"
                placeholder="请输入账号"
                class="flex-1 mt-1 pl-4 pr-4 block w-full bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
            </div>

            <div class="mt-7">
              <div class="relative">
                <input
                  ref="isShowPwd"
                  v-model="userInfo.password"
                  type="password"
                  placeholder="请输入密码"
                  class="flex-1 mt-1 pl-4 pr-4 block w-full bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
                />
                <div
                  class="absolute flex left-74 bottom-1 cursor-pointer w-8 h-8 justify-center items-center"
                  @click="HideEyes"
                >
                  <el-icon v-show="eyes"><Hide /></el-icon>
                  <el-icon v-show="!eyes"><View /></el-icon>
                </div>
              </div>
            </div>

            <div class="mt-10 flex">
              <label
                for="remember_me"
                class="inline-flex items-center w-full cursor-pointer"
              >
                <input
                  id="remember_me"
                  v-model="userInfo.isRemember"
                  type="checkbox"
                  class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  name="remember"
                />
                <span class="ml-2 text-sm text-gray-600"> 记住我 </span>
              </label>

              <div class="w-full text-right" @click="toFind">
                <div
                  class="underline text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                >
                  忘记账号或密码?
                </div>
              </div>
            </div>

            <div class="mt-7">
              <button
                class="bg-blue-500 w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="onSubmit"
              >
                登录
              </button>
            </div>

            <div class="flex mt-7 items-center text-center">
              <hr class="border-gray-300 border-1 w-full rounded-md" />
              <label class="block font-medium text-sm text-gray-600 w-full">
                新用户
              </label>
              <hr class="border-gray-300 border-1 w-full rounded-md" />
            </div>

            <div class="flex mt-4 mb-3 justify-center w-full">
              <div
                class="flex justify-center items-center bg-green-600 border-none px-4 py-2 rounded-xl cursor-pointer text-white shadow-xl hover:shadow-inner transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="register"
              >
                <!-- <i-mdi-wechat style="font-size: 1.2em; color: #fff" /> -->
                <span class="ml-1 text-sm">注册账号</span>
              </div>
            </div>
          </div>
          <!-- <form class="mt-10" @click="onSubmit"></form> -->
        </div>
      </div>
    </div>
  </div>
</template>

