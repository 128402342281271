import { ElMessage } from "element-plus";

export function useMessage(type, message) {
  ElMessage({
    message,
    type,
    duration: 3000,
    customClass: "message-box-custom-class"
  });
}
