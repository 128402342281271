<script>
export default {
  setup() {}
};
</script>

<template>
  <el-col>
    <div class="px-2 w-full">
      <div
        class="border border-white border-solid grid-cols-3 gap-4 rounded-xl shadow-lg shadow-base w-full h-[400px]"
      >
        <div class="flex">
          <div class="flex-1">
            <div
              class="h-[400px] bg-no-repeat bg-center bg-contain"
              :style="{'background-image': 'url(' + require('@/assets/img/11232134324.png') + ')' }"
            ></div>
            <!-- <img
              class="rounded-md ml-12 mycenter"
              src="src/assets/img/11232134324.png"
              alt="avatar"
            /> -->
          </div>
          <div class="flex-1">
            <div class="mycenter p-10">
              <div class="border-t-4 border-solid border-black w-16 sm:w-20 md:w-32 lg:w-48 mb-4"></div>
              <h1 class="text-4xl md:text-xl lg:text-5xl">WELCOME</h1>
              <div class="border-t-4 border-solid border-black max-w-xs mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-col>
</template>

<style lang="scss" scoped>
.el-col {
  margin: 8px 0;
}

.mycenter {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// img {
//   max-width: 80%;
//   // min-width: 100%;
// }
</style>
