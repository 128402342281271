<template>
  <div class="MochiBox /" :class="[shiba, { pop: state.pop }]" tabindex="0" @click="randomize">
    <MochiShiba
      :size="state.size"
      :mood="state.mood"
      :left-eye="state.leftEye"
      :right-eye="state.rightEye"
      :left-ear="state.leftEar"
      :right-ear="state.rightEar"
      :blush="state.blush"
    />
    <div class="MochiContent">
      <slot></slot>
    </div>
    <!-- <MochiPaws :size="state.size" /> -->
  </div>
</template>

<script setup>
import MochiShiba from './Shiba'
import { reactive, onMounted } from 'vue'
const props = defineProps({
  shiba: { type: String, default: "okaka" },
  size: { type: String, default: "medium" },
  mood: { type: String, default: "" },
  leftEye: { type: String, default: "open" },
  rightEye: { type: String, default: "open" },
  leftEar: { type: String, default: "up" },
  rightEar: { type: String, default: "flat" },
  blush: { type: Boolean, default: false },
  pop: { type: Boolean, default: true }
});

const state = reactive({
  size: props.size,
  shiba: props.shiba,
  mood: props.mood,
  leftEye: props.leftEye,
  rightEye: props.rightEye,
  leftEar: props.leftEar,
  rightEar: props.rightEar,
  blush: props.blush,
  pop: props.pop,
  canRandom: false
});

function randomArr(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function randomize() {
  if (state.canRandom) {
    state.shiba = randomArr([
      "ume",
      "sesame",
      "tuna",
      "okaka",
      "anko",
      "kinako",
      "sakura",
      "monaka"
    ]);
    state.mood = randomArr(["", "happy", "content", "excited", "cheeky", "drool", "cute", "gleam"]);
    state.leftEye = randomArr(["open", "wink", "shy", "laugh"]);
    state.rightEye = randomArr(["open", "wink", "shy", "laugh"]);
    state.leftEar = randomArr(["up", "down", "flat", "middle"]);
    state.rightEar = randomArr(["up", "down", "flat", "middle"]);
    state.blush = randomArr([true, false]);
  }
}

if (props.shiba === "random") {
  state.canRandom = true;
  randomize();
}

onMounted(() => {
  let time = 3000 + Math.random() * 2000;
  setTimeout(() => {
    state.pop = false;
  }, time);
});
</script>

<style scoped>
@import "./style.css";
</style>
