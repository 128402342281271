import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/loginModel/login.vue'
import RegisterView from '../views/loginModel/Register.vue'
import FindView from '../views/loginModel/Find.vue'
import Errors from '../views/404View.vue'
import LoginPage from '../views/LoginPageView.vue'
import Layouts from '@/layouts/common-page.vue'

const routes = [
  {
    path: '/login',
    component: LoginPage,
    children: [
      
      {
        path: '',
        component: LoginView,
      },
      {
        path: 'register',
        component: RegisterView
      },
      {
        path: "find",
        component: FindView
      },
    ]
  },
  {
    path: '/',
    component: Layouts,
    meta: { auth: true, menu: { title: "主页", icon: "Monitor", hideParent: true, sort: 1 } },
    children: [{
      path: '/',
      name: 'home',
      component:HomeView,
      meta: {
        menu: { title: "主页" }
      }
    }]
  },
  {
    path: "/",
    component: Layouts,
    meta: { auth: true, menu: { title: "账户中心", icon: "User", hideParent: true, sort: 2 } },
    children: [
      {
        name: "userInfo",
        path: "userInfo",
        component: () => import("@/views/alive/UserInfo.vue"),
        meta: { menu: { title: "账户中心" }, hideParent: true, sort: 2 }
      }
    ]
  },
  {
    path: "/",
    component: Layouts,
    meta: {
      auth: true,
      menu: { title: "购买套餐", icon: "ShoppingCart", hideParent: true, sort: 3 }
    },
    children: [
      {
        name: "pay",
        path: "/pay",
        component: () => import("@/views/pay/Pay.vue"),
        meta: { menu: { title: "购买套餐" } }
      }
    ]
  },
  {
    path: "/",
    component: Layouts,
    meta: { auth: true, menu: { title: "Chat-GTP", icon: "MoonNight", hideParent: true, sort: 4 } },
    children: [
      {
        name: "iframe",
        path: "/iframe",
        component: () => import("@/layouts/empty-page.vue"),
        meta: {
          menu: { title: "Chat-GTP" }
        }
      }
    ]
  },
  {
    path: "/",
    component: Layouts,
    meta: { menu: { title: "用户协议", icon: "InfoFilled", hideParent: true, sort: 6 } },
    children: [
      {
        name: "aboutSub",
        path: "/about",
        component: () => import("@/views/about/About.vue"),
        meta: { menu: { title: "用户协议", icon: "InfoFilled" } }
      }
    ]
  },
  // {
  //   path: "/",
  //   redirect: {
  //     name: "home"
  //   }, // 路由重定向
  // },
  {
    path: "/:any(.*)",
    name: "notFound",
    component: Errors,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router