<script setup></script>

<template>
  <!-- // 每个页面的空白页面 -->
  <div class="h-full w-full">
    <slot></slot>
  </div>
</template>

<style scoped></style>
