import { ref } from 'vue'
import router from "@/router";
import { CacheEnum } from '@/enum/cacheEnum'
import { useStorage } from "@vueuse/core";



class Menu {
  menus = ref([]);
  route = ref(null);
  close = useStorage(CacheEnum.MENU_CLOSE_STATE, false);

  constructor() {
    this.menus.value = [
      {
        "title": "主页",
        "icon": "Monitor",
        "hideParent": true,
        "sort": 1,
        "children": [
          {
            "title": "主页",
            "route": "home"
          }
        ]
      },
      {
        "title": "账户中心",
        "icon": "User",
        "hideParent": true,
        "sort": 2,
        "children": [
          {
            "title": "账户中心",
            "route": "userInfo"
          }
        ]
      },
      {
        "title": "购买套餐",
        "icon": "ShoppingCart",
        "hideParent": true,
        "sort": 3,
        "children": [
          {
            "title": "购买套餐",
            "route": "pay"
          }
        ]
      },
      {
        "title": "Chat-GTP",
        "icon": "MoonNight",
        "hideParent": true,
        "sort": 4,
        "children": [
          {
            "title": "Chat-GTP",
            "route": "iframe"
          }
        ]
      },
      {
        "title": "用户协议",
        "icon": "InfoFilled",
        "hideParent": true,
        "sort": 6,
        "children": [
          {
            "title": "用户协议",
            "icon": "InfoFilled",
            "route": "aboutSub"
          }
        ]
      }
    ]
  }

  /**
   * 获取嵌套路由的面包屑
   * @param m 菜单对象
   * @param routerMap 用于存储路由名称对应的面包屑名称
   * @param title 用于连接面包屑名称
   */
  getNestedMenuByRoute(m, routerMap, title = "") {
    m.children?.forEach((c) => {
      title !== "" ? (title = `${title}-${c.title}`) : (title = `${m.title}-${c.title}`);
      routerMap.set(c.route, title);
      if (c.children) {
        this.getNestedMenuByRoute(c, routerMap, title);
      } else {
        title = "";
      }
    });
  }

  getCurrentMenu(route) {
    const routerMap = new Map();
    this.menus.value?.forEach((m) => {
      this.getNestedMenuByRoute(m, routerMap);
    });
    return routerMap.get(route.name);
  }

  linkPage(menu) {
    this.isExternalLink(menu) ? window.open(menu.path) : router.push({ name: menu.route });
  }

  isExternalLink(menu) {
    return !!menu.path;
  }

  toggleState() {
    this.close.value = !this.close.value;
  }

  /**
   * 组装嵌套菜单对象
   * @param children 嵌套的子菜单
   * @returns 嵌套菜单对象
   */
  filterNestedMenu(children) {
    return children
      .filter((route) => route.meta?.menu)
      .map((route) => {
        if (route.children) {
          const childRoute = this.filterNestedMenu(route.children);
          return { ...route.meta?.menu, route: route.name, children: childRoute };
        }
        return { ...route.meta?.menu, route: route.name };
      })
  }

  // 根据路由元数据构建菜单列表
  getMenuByRoute() {
    return router
      ?.getRoutes()
      .filter((route) => route.children.length && route.meta.menu && !route.meta.menu.nested)
      .map((route) => {
        const menu = { ...route.meta?.menu };
        menu.children = this.filterNestedMenu(route.children);
        return menu;
      })
      .filter((menu) => menu.children?.length)
      .sort((a, b) => {
        return (a.sort || 0) - (b.sort || 0);
      })
  }
}

export default new Menu();
