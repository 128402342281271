export function useLoginPlaceholder() {
  const passwordInputText = computed(() => {
    return "请输入密码";
  });

  const mailInputText = computed(() => {
    return "请输入手机号";
  });

  return {
    passwordInputText,
    mailInputText
  };
}
export function validatePhoneNumber(phoneNumber) {
  if (phoneNumber.length !== 11) {
    return false;
  }
  const reg =
    /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0-3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
  return reg.test(phoneNumber);
}

export function checkEmail(email) {
  const reg = /^[1-9]\d{4,10}@qq.com$/;
  return reg.test(email);
}
