<script >
import { reactive, ref, onUnmounted } from "vue";
import { useMessage } from "@/utils/useMessage.js";
import { useRouter } from "vue-router";
import { http } from "@/utils/http";
import { validatePhoneNumber, checkEmail } from "@/utils/login.js";
import { ArrowLeftBold } from "@element-plus/icons-vue";
export default {
  components: {
    ArrowLeftBold,
  },
  setup() {
    const router = useRouter();

    const codeBtn = ref(true);
    const userInfo = reactive({
      username: "",
      password: "",
      rePassword: "",
      email: "",
      emailCode: "",
      num: 59,
      isShowCode: false,
    });
    const onSubmit = () => {
      if (userInfo.username == "") {
        useMessage("error", "账号不能为空！");
        return;
      }

      if (userInfo.password == "") {
        useMessage("error", "密码不能为空！");
        return;
      }
      if (userInfo.rePassword == "") {
        useMessage("error", "请再次输入密码！");
        return;
      }
      if (userInfo.email == "") {
        useMessage("error", "邮箱不能为空！");
        return;
      }
      // if (!validatePhoneNumber(userInfo.username)) {
      //   useMessage("error", "账号必须为手机号！");
      //   return;
      // }
      if (!checkEmail(userInfo.email)) {
        useMessage("error", "邮箱格式不正确！只支持QQ邮箱");
        return;
      }
      if (userInfo.password.length < 6) {
        useMessage("error", "密码不能少于6位数");
        return;
      }
      if (userInfo.password !== userInfo.rePassword) {
        useMessage("error", "两次密码不一致");
        return;
      }
      if (userInfo.emailCode == "") {
        useMessage("error", "邮箱验证码不能为空");
        return;
      }
      http
        .post("/v1/user/register", {
          username: userInfo.username,
          password: userInfo.password,
          rePassword: userInfo.rePassword,
          emailCode: userInfo.emailCode,
          email: userInfo.email,
        })
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          router.push("/login");
        }).catch(error => {
      console.error("发生错误:", error);
      // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
    });
      // router.push("/home");
    };
    const toLogin = () => {
      router.push("/login");
    };

    const codeinterval = ref(0);

    // 发送验证码
    const sendCode = () => {
      userInfo.num = 59
      userInfo.emailCode =""
      if (userInfo.username == "") {
        useMessage("error", "账号不能为空！");
        return;
      }
      if (userInfo.password == "") {
        useMessage("error", "密码不能为空！");
        return;
      }
      if (userInfo.rePassword == "") {
        useMessage("error", "请再次输入密码！");
        return;
      }
      if (userInfo.email == "") {
        useMessage("error", "邮箱不能为空！");
        return;
      }
      // if (!validatePhoneNumber(userInfo.username)) {
      //   useMessage("error", "账号必须为手机号！");
      //   return;
      // }
      if (!checkEmail(userInfo.email)) {
        useMessage("error", "邮箱格式不正确！只支持QQ邮箱");
        return;
      }
      if (userInfo.password.length < 6) {
        useMessage("error", "密码不能少于6位数");
        return;
      }
      if (userInfo.password !== userInfo.rePassword) {
        useMessage("error", "两次密码不一致");
        return;
      }

      http
        .post("/v1/user/sendCode", {
          email: userInfo.email,
        })
        .then((res) => {
          if (res.data.code != 200) {
            useMessage("error", res.data.message);
            return;
          }
          useMessage("success", res.data.message);
          codeBtn.value = false;
          userInfo.isShowCode = true;
          codeinterval.value = window.setInterval(() => {
            userInfo.num--;
            if (userInfo.num == 0) {
              codeBtn.value = true;
              window.clearInterval(codeinterval.value);
              userInfo.num = 59;
            }
          }, 1000);
        }).catch(error => {
      console.error("发生错误:", error);
      // 或者将错误状态存储到Vue组件中的数据中，并在页面上显示友好的提示
    });
    };

    onUnmounted(() => {
      window.clearInterval(codeinterval.value);
    });

    return {
      userInfo,
      onSubmit,
      toLogin,
      codeBtn,
      sendCode,
    };
  },
};
</script>
<template>
  <div class="h-screen font-sans login bg-cover">
    <div
      class="container mx-auto h-full flex flex-1 justify-center items-center"
    >
      <div class="relative mx-10 sm:max-w-lg w-full">
        <div
          class="card bg-blue-400 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"
        ></div>
        <div
          class="card bg-red-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"
        ></div>
        <div
          class="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md"
        >
          <label
            for=""
            class="block mt-10 text-2xl text-gray-700 text-center font-semibold"
          >
            注册账号
          </label>
          <div class="mt-10">
            <div class="relative flex pl-7 pr-7">
              <div class="flex justify-center items-center font-light w-20">
                账号：
              </div>
              <input
                v-model="userInfo.username"
                placeholder="请输入手机号"
                class="mt-1 pl-4 block w-full bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
            </div>
            <!--  class="mt-1 pl-4 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0" -->

            <div class="relative flex pl-7 pr-7 mt-7">
              <div class="flex justify-center items-center font-light w-20">
                密码：
              </div>
              <input
                v-model="userInfo.password"
                placeholder="请输入密码"
                class="mt-1 pl-4 block w-full bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
            </div>
            <div class="relative flex pl-7 pr-7 mt-7">
              <div
                class="flex justify-center items-center font-light p-1 whitespace-nowrap"
              >
                再次输入密码：
              </div>
              <input
                v-model="userInfo.rePassword"
                placeholder="请再次输入密码"
                class="mt-1 pl-4 block w-full  bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
            </div>
            <div class="flex relative flex pl-7 pr-7 mt-7">
              <div class="flex justify-center items-center font-light ml-2">
                邮箱：
              </div>
              <input
                v-model="userInfo.email"
                placeholder="请输入邮箱"
                class="flex-1 mt-1 pl-4 block  bg-gray-100 h-11 rounded-xl shadow-lg focus:border focus:outline-none focus:border-blue-400 hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
              <button
                v-show="!codeBtn"
                class="whitespace-nowrap bg-gray-300 text-sm rounded-xl text-white focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x ml-2 pl-3 pr-3"
                disabled
              >
                <div>
                  <span>{{ userInfo.num }}s </span>后重新发送
                </div>
              </button>
              <button
                v-show="codeBtn"
                class="whitespace-nowrap bg-red-500 text-sm rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105 ml-2 pl-3 pr-3"
                @click="sendCode"
              >
                <span>发送邮箱验证码</span>
              </button>
            </div>
            <div
              v-show="userInfo.isShowCode"
              class="flex relative flex pl-7 pr-7 mt-7 justify-end"
            >
              <div class="flex justify-center items-center font-light ml-2">
                邮箱验证码：
              </div>
              <input
                v-model="userInfo.emailCode"
                placeholder="请输入邮箱验证码"
                class="mt-1 pl-4 block border-none w-44 bg-gray-100 h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-blue-100 focus:ring-0"
              />
            </div>

            <div class="flex mt-12 pl-8 pr-8 mb-10">
              <button
                class="flex items-center justify-center bg-blue-500 font-normal w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="toLogin"
              >
                <el-icon><ArrowLeftBold /></el-icon>
                <div>返回登录</div>
              </button>
              <button
                class="bg-blue-500 ml-20 font-normal w-full py-2 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105"
                @click="onSubmit"
              >
                确认注册
              </button>
            </div>
          </div>
          <!-- <form class="mt-10" @click="onSubmit"></form> -->
        </div>
      </div>
    </div>
  </div>
</template>

