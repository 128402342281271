import { ElementEnum } from "@/enum/elementEnum";
import { ElMessageBox } from "element-plus";

export function useDialog(msg, successCallback, errCallback) {
  ElMessageBox.confirm(msg, undefined, {
    customStyle: {
      width: ElementEnum.ELEMENT_MESSAGE_BOX_SIZE
    }
  })
    .then(() => {
      successCallback();
    })
    .catch((err) => {
      errCallback && errCallback(err);
    });
}
