<script setup>
import Card from "@/components/Card";
import MochiBox from "@/components/Mochi/Box";
import { changeDateFormat } from "@/utils/utils.js";
import { useRouter } from "vue-router";
const router = useRouter();
const handPay = () => {
  router.push("/pay");
};
</script>

<template>
  <Card
    :err-capture="$store.state.userInfo?.length ? false : true"
    card-height="h-[430px] w-full"
  >
    <template #title>
      <div class="flex overflow-hidden">
        <div class="text-lg	whitespace-nowrap">套餐信息</div>
        <el-tag class="ml-2 text-2xl" type="danger" size="large">
         <div class="text-sm w-full w-[520px] break-words">
            (包月套餐次数是每天刷新,次卡不限制时间用完为止,点击卡片可以跳转对应的网站)
          </div>
          </el-tag
        >
      </div>
    </template>
    <template #error>
      <div
        class="flex justify-center items-center h-[220px] w-8/12 text-center mx-auto mt-14"
      >
        <p class="text-lg font-semibold text-gray-500 text-red-500">
          暂无套餐信息(或套餐已到期),请点击在线购买
        </p>
        <button
          class="bg-blue-500 p-2 pl-6 pr-6 rounded-lg ml-5 text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out transform"
          @click="handPay"
        >
          激活套餐 / 在线购买
        </button>
      </div>
    </template>
    <template #content>
      <el-scrollbar height="350px" class="" :always="true">
        <div class="taocan_scrollbar">
          <a
            v-for="(data, index) in $store.state.userInfo"
            :key="`${data.id}_${index}`"
            target="_blank"
            :href="data.link"
          >
            <!-- <a v-for="(data) in $store.state.userInfo" :key="data.id" target="_blank" :href="data.link"> -->
            <MochiBox
              :shiba="data.icon"
              mood="drool"
              left-ear="up"
              right-ear="up"
              class="cursor-pointer"
            >
              <div class="flex mt-2">
                <div style="font-size: 20px">{{ data.name }}</div>
                <span
                  v-if="data.name !== '昵图网'"
                  class="flex items-end ml-1 text-gray-400"
                  >(剩:{{ data.download_num }}次)</span
                >
                <span
                  v-if="data.name == '昵图网'"
                  class="flex items-end ml-1 text-gray-400"
                  >(剩:{{ data.download_num }}分)</span
                >
              </div>

              <!-- <h3 style="margin-top: 10px; font-size: 20px">{{ data.name }}</h3> -->

              <p class="shop kotobuki" title="kotobuki">
                到期时间:{{ changeDateFormat(data.time) }}
              </p>
            </MochiBox>
          </a>
        </div>
      </el-scrollbar>
    </template>
  </Card>
</template>

<style scoped>
.time-line-box :deep(.el-timeline-item__node--large) {
  left: -4px !important;
}
.shop {
  border-radius: 20px;
  display: inline-block;
  background-color: #356320;
  color: white;
  font-size: 12px;
  padding: 3px 6px;
  margin-top: 10px;
}
.taocan_scrollbar {
  margin-left: 26px;
  margin-top: 40px;
  --auto-grid-min-size: 200px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 15px;
}
</style>
