import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/tailMain.css'
import LoadingPlugin from '@/plugins/loading.js';
import '@/plugins/styles.css';
import '@/plugins/sweetalert2.css'
import { setupTailwindcss } from "@/plugins/tailwindcss/index";



const app = createApp(App)
  .use(store)
  .use(router)
  .use(LoadingPlugin)
  .use(ElementPlus);

setupTailwindcss();
app.mount("#app");


const debounce = (fn, delay) => {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }
  
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }
