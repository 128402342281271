<script setup>
import { Setting } from "@element-plus/icons-vue";
import { useNow, useDateFormat } from "@vueuse/core";
import { useDialog } from "@/hooks/useDialog";
import { useMessage } from "@/hooks/useMessage";
import { removeToken } from "@/utils/auth";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

const router = useRouter();
const store = useStore();
const src = require('@/assets/img/keda.webp')
const formatted = useDateFormat(useNow(), "YYYY-MM-DD HH:mm");

// 退出登录
function toLogin() {
  useDialog("是否确认退出系统？", () => {
    removeToken(() => {
      router.replace("/login");
      useMessage("success", "退出成功");
    });
  });
}
</script>

<template>
  <el-avatar shape="square" :size="40" :src="src" />
  <div class="flex flex-col items-start">
    <span class="font-sans dark:text-white text-gray-600 text-sm font-medium">{{
      "你好," + store.state.username
    }}</span>
    <span class="font-sans dark:text-white text-gray-500 text-sm">{{ formatted }}</span>
  </div>
  <el-dropdown>
    <el-icon :size="20" class="ml-2 mt-[1px] hover:rotate-90 duration-500 cursor-pointer"
      ><setting
    /></el-icon>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="toLogin">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style scoped></style>
