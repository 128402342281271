<script setup>
import { ArrowRight } from "@element-plus/icons-vue";
import menuService from "@/hooks/useMenu";
// import { useI18n } from "vue-i18n";
import { useRoute } from 'vue-router'
import { ref, watchEffect } from 'vue'

const route = useRoute();
// const { t } = useI18n();
const currentBread = ref("");
const breadArr = ref([]);

watchEffect(() => {
  currentBread.value = menuService.getCurrentMenu(route)
  breadArr.value = [...new Set(currentBread.value?.split("-"))];
});
</script>

<template>
  <div class="breadcrumb">
    <el-breadcrumb :separator-icon="ArrowRight">
      <el-breadcrumb-item v-for="(bread, index) in breadArr" :key="index">{{
        bread
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}
</style>
