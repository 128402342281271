function changeDateFormat(time, format = "YYYY-MM-DD hh:mm") {
  if (!time) return "";
  var datetime = new Date(time);
  var o = {
    "Y+": datetime.getFullYear(),
    "M+": datetime.getMonth() + 1,
    "D+": datetime.getDate(),
    "h+": datetime.getHours(),
    "m+": datetime.getMinutes(),
    "s+": datetime.getSeconds(),
    "q+": Math.floor((datetime.getMonth() + 3) / 3),
    S: datetime.getMilliseconds()
  };
  if (/(Y+)/.test(format))
    format = format.replace(RegExp.$1, (datetime.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  return format;
}

export { changeDateFormat };
